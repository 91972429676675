@import url("https://cdn.syncfusion.com/ej2/material.css");

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent
    url("https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png")
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #f6f6f6;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  /* line-height: 5rem; */
  max-width: 600px;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #e48f0f;
}
.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}
.footer-wrapper {
  margin: 6rem 0rem;
  display: flex;
  margin-top: 10rem;
}
.footer-logo-container {
  max-width: 110px;
}
.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 15rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    max-width: 95vw;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.popup.open {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
